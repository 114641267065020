<template>
  <pt-password-setting class="pt-password-setting__withData" @getCode="getCode" ref="form" @editUserPassword="editUserPassword"></pt-password-setting>
</template>

<script>
import PtPasswordSetting from './index'
import {hasPermissionsOfTrust} from "formats/permissions.js"
export default {
  name: 'pt-password-setting__withData',
  components: {
    'pt-password-setting': PtPasswordSetting
  },
  computed:{
    userMessage (){
      return this.$store.state.currentUserMessage || {}
    },
    mobile (){
      return this.userMessage.mobile
    }
  },
  methods:{
    editUserPassword (formValue){
      let {code, newPassword} = formValue
      let data = {
        code, newPassword
      }
      this.$store.dispatch('editUserPassword', data).then(res => {
        this.$toast.success('修改密码成功')
        let path = hasPermissionsOfTrust() ? '/Home' : '/task-order'
        setTimeout(_ => {
          this.$router.push(path)
        }, 3000)
      }).catch(err => {
        this.$toast.error(err || '修改密码失败')
      })
    },
    getCode (getCodeVM){
      let data = {
        mobilePhone: this.mobile,
        businessKey: "verify"
      };
      this.$store
        .dispatch("getCode", data)
        .then((res) => {
          if (res.data && res.data.message) {
            this.$toast.info(res.data.message);
          }
          // 自动聚焦到验证码输入框
          this.$refs.form.autofocusAuthorizationCode = true;
        })
        .catch((errorMessage) => {
          this.$toast.error(errorMessage);
          getCodeVM.reset();
        });
    }
  }
}
</script>
