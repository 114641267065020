<template>
  <pt-password-setting class="pt-password-setting__page"></pt-password-setting>
</template>

<script>
import PtPasswordSetting from 'cps/pt-user-setting/pt-password-setting/withData'
export default {
  name: 'pt-password-setting__view',
  components: {
    'pt-password-setting': PtPasswordSetting
  }
}
</script>
