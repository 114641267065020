<template>
  <div :class="$style[classes]">
		<validate-form tag="table" ref="form" :class="$style['pt-password-setting-form']">
				<wt-form-item label="验证码：" name="验证码" ref="formItem" tag="div"  rules="required" :widthMax="width" lineHeight="30" :showError="false">
					<wt-input v-model="formValue.code" placeholder="请输入您的验证码">
            <wt-count-down-box
              slot="rt"
              ref="countDownBox"
              :class="$style.getValidCode"
              @on-start="startGetCode"
              >获取验证码</wt-count-down-box
            >
					</wt-input>
				</wt-form-item>
					<wt-form-item label="新密码：" name="新密码" ref="formItem" tag="div"  rules="required|password" :widthMax="width" lineHeight="30" :showError="false">
						<wt-input v-model="formValue.newPasswordFirst" placeholder="请输入您的新密码" type="password"  :eye="{open:false}"></wt-input>
					</wt-form-item>
					<wt-form-item label="确认密码：" name="确认密码" ref="formItem" tag="div"  rules="required|password" :widthMax="width" lineHeight="30" :showError="false">
						<wt-input v-model="formValue.newPassword" placeholder="请再次输入您的新密码" type="password" :eye="{open:false}"></wt-input>
					</wt-form-item>
		</validate-form>
		<div class="tip-rules-password">密码只能由6-15位字母、数字、符号组成</div>
		<div :class="$style['button-div']">
			<wt-button @click="submit" primary :class="$style['next-button']">确认修改</wt-button>
		</div>
	</div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-password-setting'

import formMixins from 'mixins/form'
export default {
  name: 'pt-password-setting',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  mixins:[formMixins],
  data (){
    return {
      formValue:{},
      width:80
    }
  },
  methods:{
    submit (){
      this.validate('form').then(res => {
        if(this.formValue.newPasswordFirst === this.formValue.newPassword){
          this.$emit('editUserPassword', this.formValue)
        }else{
          this.$toast.error('两次输入密码不一致，请重新输入');
					
        }
      })
    },
    startGetCode (next){
      next();
      this.$emit("getCode", this.$refs.countDownBox);
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-password-setting{
	:global{
		.tip-rules-password{
			text-align: left;
			padding: 20px @page-padding ;
			color: red;
			font-size: 13px;
		}
	}
}
.pt-password-setting-form{
	background-color: #fff;
	width: 100%;
	:global{
			.wt-input,.wt-select{
				padding: 5px 15px;
			}
			.cube-input{
				background-color:rgba(255, 255, 255, 0)
			}
		.wt-form-list-item{
			padding:10px @page-padding;
			display: flex;
			width: 100%;
			margin-bottom:0;
			box-sizing: border-box;
			+.wt-form-list-item{
			border-top:1Px solid @font-of-grey;
			}
      .wt-count-down-box {
        color: @main-color!important;
				// font-size: 13px;
				border:none;
				padding-right: 0;
				min-width: 5em;
      }
			.label-text{
				justify-content:center
			}
		}
	}
}
.button-div{
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
	.next-button{
		width: 100%
	}
}
</style>
